import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react'

import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import Adorno from '../components/Adorno';
import Poema from '../components/Poema';
import Fecha from '../components/Fecha';

/**
 * Página de inicio.
 */
const IndexPage = () => {

  // Consulta para obtener la imagen de fondo de la página
  // de inicio y la última poesía publicada.
  const data = useStaticQuery(indexQuery);
  const post = data.allMdx.nodes[0].frontmatter;
  const body = data.allMdx.nodes[0].body;

  // Página temporal
  let ready = false;

  if (! ready) {
    return (
      <Layout>
        <SEO title="Inicio" />
        <Presentacion>
          <p>
            Bienvenido a un rincón para la poesía.
          </p>
        </Presentacion>
      </Layout>
    );  
  }

  // Renderizado
  return (
    <Layout>
      <SEO title="Inicio" />
      <Presentacion>
        <p>
          Bienvenido a un rincón para la poesía.
        </p>
      </Presentacion>
      <Poema>
        <h2>{post.title}</h2>
        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        <Fecha>{post.fecha}</Fecha>
      </Poema>
      <Adorno>
        <Image fluid={data.imageSharp.fluid} alt="Adorno floral" />
      </Adorno>
    </Layout>
  );
}

// Componente con estilo personalizado
const Presentacion = styled.div`
  width: 320px;
  margin: auto;

  p {
    font-family: var(--ff-text);
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
    line-height: 1.3;
    text-align: center;
  }

  @media (max-width: 440px) {
    p {
      font-size: 16px;
    }
  }
`;

// Consulta para obtener la imagen de la página de inicio y la
// última poesía incorporada
export const indexQuery = graphql`
  query {
    imageSharp(fluid: {originalName: {eq: "watercolor-17.png"}}) {
        fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
        }
    },
    allMdx(sort: {order: DESC, fields: frontmatter___date}, limit: 1) {
      nodes {
        frontmatter {
          title
          slug
          date
          fecha
        }
        body
      }
    }
  }
`;

// Componente para sustituir los párrafos por "pre"
const Parrafo = (props) => <pre {...props} />;

const components = {
  p: Parrafo
}

export default IndexPage;
